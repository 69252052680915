// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Checkbox, Modal, TextInput, useTheme } from "@merit/frontend-components";
import { Formik } from "formik";
import { HorizontalSpacer, VerticalSpacer } from "../../../components/Spacer";
import { Spin } from "../../../components";
import { StyleSheet, View } from "react-native";
import { checkIsFieldInvalid } from "../common";
import { editAdminValidationSchema } from "../schema";
import { getContainerFieldValue } from "@src/utils/getContainerFieldValue";
import { useAlertStore } from "../../../stores/alertStore";
import { useApi } from "../../../api/api";
import { useAppConstantsStore } from "@src/stores";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useOrgAllowedAdminRoles } from "@src/hooks/useOrgAllowedAdminRoles";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";
import type { OrgsGet200ResponseContainersInner as Container } from "../../../gen/org-portal";
import type { OPTestProps } from "../../../types/TestProps";

export type FormValues = {
  readonly adminRoles: readonly string[];
};

type Props = {
  readonly adminMerit: Container;
  readonly onClose: () => void;
  readonly testProps: OPTestProps;
};

export const EditAdminRolesModal = ({ adminMerit, onClose, testProps }: Props) => {
  const { theme } = useTheme();
  const [isEditRequestInFlight, setIsEditRequestInFlight] = useState(false);
  const { deleteAlert, setAlert } = useAlertStore();
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const { errorHandler } = useServerErrorHandler();
  const { adminMeritFieldNames, meritFieldNames } = useAppConstantsStore();
  const { isLoading: isRolesLoading, roles } = useOrgAllowedAdminRoles();

  const styles = StyleSheet.create({
    horizontalFields: {
      alignSelf: "flex-end",
      width: 74,
    },
  });

  const submitForm = async (values: FormValues) => {
    try {
      const adminEmail = getContainerFieldValue(meritFieldNames.email, adminMerit);
      const adminOrgUUID = getContainerFieldValue("Org UUID", adminMerit);

      if (adminEmail === undefined) {
        throw new Error("attempted to edit admin with no email");
      }

      if (adminOrgUUID === undefined) {
        throw new Error("attempted to edit admin with no orgID");
      }

      setIsEditRequestInFlight(true);
      await api.editAdminRoles({
        adminID: adminMerit.recipient?.id ?? "",
        editAdminRolesRequest: {
          adminEmail,
          adminOrgUUID,
          adminRoles: [...values.adminRoles],
        },
        orgID: selectedOrgId,
      });

      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        testProps: {
          elementName: "editAdminSuccess",
          screenName: testProps.screenName,
        },
        text: `Permissions for ${adminEmail} were successfully updated`,
        type: "success",
      });
    } catch (error) {
      errorHandler(error, {
        elementName: "editAdminError",
        screenName: testProps.screenName,
      });
    } finally {
      onClose();
      setIsEditRequestInFlight(false);
    }
  };

  const rolesValue = getContainerFieldValue(adminMeritFieldNames.roles, adminMerit);
  const parsedRoles: readonly string[] = rolesValue === undefined ? [] : JSON.parse(rolesValue);

  return (
    <Modal
      onClose={onClose}
      testProps={{
        ...testProps,
        elementName: `${testProps.elementName}Modal`,
      }}
      title="Edit admin"
      width={450}
    >
      <Spin spinning={isEditRequestInFlight || isRolesLoading}>
        <Formik
          initialValues={{
            adminRoles: [...parsedRoles],
          }}
          onSubmit={(values: FormValues) => {
            submitForm(values);
          }}
          validationSchema={editAdminValidationSchema}
        >
          {({ errors, getFieldMeta, handleSubmit, setFieldValue, values }) => (
            <>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <View>
                  <TextInput
                    label="First name"
                    placeholder="First name"
                    testProps={{
                      ...testProps,
                      elementName: `${testProps.elementName}AdminFirstNameTextInput`,
                    }}
                    value={getContainerFieldValue(meritFieldNames.firstName, adminMerit)}
                  />
                </View>
                <HorizontalSpacer size={theme.spacing.m} />
                <View>
                  <TextInput
                    label="Last name"
                    placeholder="Last name"
                    testProps={{
                      ...testProps,
                      elementName: `${testProps.elementName}AdminLastNameTextInput`,
                    }}
                    value={getContainerFieldValue(meritFieldNames.lastName, adminMerit)}
                  />
                </View>
              </View>
              <VerticalSpacer size={theme.spacing.s} />
              <TextInput
                label="Email"
                placeholder="Email"
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}AdminEmailTextInput`,
                }}
                value={getContainerFieldValue(meritFieldNames.email, adminMerit)}
              />
              <VerticalSpacer size={theme.spacing.m} />

              <View>
                {roles.map(ar => (
                  <Checkbox
                    defaultChecked={values.adminRoles.includes(ar)}
                    key={ar}
                    label={ar}
                    onChange={isChecked => {
                      setFieldValue(
                        `adminRoles`,
                        isChecked
                          ? [...values.adminRoles, ar]
                          : values.adminRoles.filter(x => x !== ar)
                      );
                    }}
                  />
                ))}
                {checkIsFieldInvalid(getFieldMeta, "adminRoles") && (
                  <Body
                    color={theme.colors.text.alert.critical}
                    testProps={{
                      ...testProps,
                      elementName: `${testProps.elementName}AdminRolesErrorMessage`,
                    }}
                  >
                    {errors.adminRoles}
                  </Body>
                )}
              </View>

              <VerticalSpacer size={theme.spacing.m} />
              <View style={styles.horizontalFields}>
                <Button
                  onPress={handleSubmit}
                  size="medium"
                  testProps={{
                    ...testProps,
                    elementName: `${testProps.elementName}SaveButton`,
                  }}
                  text="Save"
                  type="primary"
                />
              </View>
            </>
          )}
        </Formik>
      </Spin>
    </Modal>
  );
};
