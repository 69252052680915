import { useAppConstantsStore, useAuthStore } from "@src/stores";
import { useMemo } from "react";

export const useLoggedInUserRoles = () => {
  // Not using useLoggedInAuthState because of the asserts and this hook may be
  // used in the Router component, where there are code branches that the user is
  // not logged in
  const { profile } = useAuthStore();
  const { adminRoleNames } = useAppConstantsStore();

  return useMemo(() => {
    const userRoles = profile === null ? [] : profile.userRoles;

    return {
      isCheckinAdmin: Boolean(userRoles.includes(adminRoleNames.checkinAdmin)),
      isDashboardAdmin: Boolean(userRoles.includes(adminRoleNames.dashboardAdmin)),
      isDesignAdmin: Boolean(userRoles.includes(adminRoleNames.designAdmin)),
      isOrgAdmin: Boolean(userRoles.includes(adminRoleNames.orgAdmin)),
      userRoles,
    };
  }, [
    adminRoleNames.checkinAdmin,
    adminRoleNames.dashboardAdmin,
    adminRoleNames.designAdmin,
    adminRoleNames.orgAdmin,
    profile,
  ]);
};
