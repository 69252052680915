// Copyright 2023 Merit International Inc. All Rights Reserved

import { BadgeGroup } from "@src/components/BadgeGroup";
import { Body, Button, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "../../../components/Modals";
import { Datagrid, DatagridBody, HorizontalSpacer, Pagination } from "../../../components";
import { EditAdminRolesModal } from "./EditAdminRolesModal";
import { EllipsisText } from "../../../components/EllipsisText";
import { Helpers } from "@merit/frontend-utils";
import { ScrollView, StyleSheet, View } from "react-native";
import { getContainerFieldValue } from "../../../utils/getContainerFieldValue";
import { getDateTimeString } from "../../../utils/time";
import { getFullName } from "../../../utils/getFullName";
import { useAdminListData } from "./useAdminListData";
import { useAlertStore } from "../../../stores/alertStore";
import { useApi } from "../../../api/api";
import { useAppConstantsStore } from "../../../stores";
import { useCallback, useState } from "react";
import { useIsFocused } from "@react-navigation/native";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useLogout } from "../../../hooks/auth";
import { useOrgAllowedAdminRoles } from "@src/hooks/useOrgAllowedAdminRoles";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import type { OrgsGet200ResponseContainersInner as Container } from "../../../gen/org-portal";
import type { DatagridColumn } from "../../../components/Datagrid/types";
import type { ViewStyle } from "react-native";

const { Some } = Helpers;
const SCREEN_NAME = "OrganizationSettings";

export const AdminManagement = ({}) => {
  const { profile, selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();
  const { theme } = useTheme();
  const { adminMeritFieldNames, adminsLimit, meritFieldNames } = useAppConstantsStore();
  const { logout } = useLogout();
  const { isLoading: isRolesLoading, roles: allowedRoles } = useOrgAllowedAdminRoles();

  const [adminToRemove, setAdminToRemove] = useState<Container>();
  const [adminToEdit, setAdminToEdit] = useState<Container>();
  const [isLoading, setIsLoading] = useState(false);
  const { errorHandler } = useServerErrorHandler();
  const isFocused = useIsFocused();

  const {
    admins,
    isLoading: isFetching,
    nextPage,
    pagination,
    prevPage,
    refresh,
  } = useAdminListData(
    api,
    {
      limit: adminsLimit,
      orgID: selectedOrgId,
    },
    isFocused
  );

  const styles = StyleSheet.create<{
    readonly rolesContainer: ViewStyle;
    readonly tabBody: ViewStyle;
  }>({
    // naive solution for making all admin rows equal height, roughly based on the height of three stacked Badge components
    rolesContainer: {
      justifyContent: "center",
      minHeight: 100,
    },
    tabBody: {
      flex: 1,
    },
  });

  const removeAdmin = useCallback(async () => {
    if (Some(adminToRemove)) {
      try {
        setIsLoading(true);
        await api.removeAdmin({ adminID: adminToRemove.id, orgID: selectedOrgId });

        setAdminToRemove(undefined);
        const firstName = getContainerFieldValue(meritFieldNames.firstName, adminToRemove) ?? "";
        const lastName = getContainerFieldValue(meritFieldNames.lastName, adminToRemove) ?? "";
        const fullName = `${firstName} ${lastName}`;

        setAlert({
          closable: true,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          size: "medium",
          testProps: {
            elementName: "adminRemoveSuccess",
            screenName: SCREEN_NAME,
          },
          text: `${fullName} was successfully removed as an admin.`,
          type: "success",
        });
        // The email key is named as the name in the profile, so here, profile.email equals profile.name.
        if (getContainerFieldValue(meritFieldNames.email, adminToRemove) === profile.name) {
          logout();
        } else {
          refresh();
        }
      } catch (error) {
        errorHandler(error, {
          elementName: "adminRemoveError",
          screenName: SCREEN_NAME,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    adminToRemove,
    api,
    deleteAlert,
    errorHandler,
    logout,
    meritFieldNames.email,
    meritFieldNames.firstName,
    meritFieldNames.lastName,
    profile.name,
    refresh,
    selectedOrgId,
    setAlert,
  ]);

  const columns: readonly DatagridColumn<Container>[] = [
    {
      key: "name",
      label: "Name",
      renderer: (container, testProps) => {
        const name = getFullName(
          getContainerFieldValue(meritFieldNames.firstName, container),
          getContainerFieldValue(meritFieldNames.lastName, container)
        );

        return <EllipsisText testProps={testProps} text={name} />;
      },
      size: "flex",
    },
    {
      key: "email",
      label: "Email",
      renderer: (container, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={getContainerFieldValue(meritFieldNames.email, container) ?? "--"}
        />
      ),
      size: "flex",
    },
    {
      key: "dateAdded",
      label: "Date added",
      renderer: (container, testProps) => (
        <Body numberOfLines={1} testProps={testProps}>
          {Some(container.createdAt) ? getDateTimeString(container.createdAt) : "--"}
        </Body>
      ),
      size: "flex",
    },
    {
      key: "status",
      label: "Status",
      renderer: (container, testProps) => (
        <EllipsisText capitalize testProps={testProps} text={container.state?.name ?? "--"} />
      ),
      size: 120,
    },
    {
      key: "roles",
      label: "Roles",
      renderer: (container, testProps) => {
        const rolesFieldValue = getContainerFieldValue(adminMeritFieldNames.roles, container);
        const roles =
          (rolesFieldValue === undefined ? undefined : JSON.parse(rolesFieldValue)) ?? [];
        if (!(roles instanceof Array)) {
          // TODO: error handling?
          return null;
        }
        const filteredRoles = roles.filter(role => allowedRoles.includes(role));
        const content =
          !(roles instanceof Array) || roles.length === 0 ? (
            <Body>--</Body>
          ) : (
            <BadgeGroup
              limit={3}
              singleColor="oceanBlue"
              testProps={testProps}
              texts={filteredRoles}
            />
          );

        return <View style={styles.rolesContainer}>{content}</View>;
      },
      size: "flex",
    },
    {
      key: "actions",
      label: "Actions",
      // eslint-disable-next-line react/no-multi-comp
      renderer: (container, testProps) => (
        <View style={{ flexDirection: "row" }}>
          <View style={{ maxWidth: 80 }}>
            <Button
              onPress={() => {
                setAdminToEdit(container);
              }}
              size="small"
              testProps={
                Some(testProps)
                  ? {
                      ...testProps,
                      elementName: `${testProps.elementName}EditButton`,
                    }
                  : testProps
              }
              text="Edit"
              type="secondary"
            />
          </View>

          <HorizontalSpacer size={16} />
          <View style={{ maxWidth: 80 }}>
            <Button
              onPress={() => {
                setAdminToRemove(container);
              }}
              size="small"
              testProps={
                Some(testProps)
                  ? {
                      ...testProps,
                      elementName: `${testProps.elementName}RemoveButton`,
                    }
                  : testProps
              }
              text="Remove"
              type="destructive"
            />
          </View>
        </View>
      ),
      size: 200,
    },
  ];

  const showRemoveAdminConfirmationModal = () => (
    <ConfirmationModal
      buttonText="remove"
      onClose={() => {
        setAdminToRemove(undefined);
      }}
      onOk={() => {
        removeAdmin();
        setAdminToRemove(undefined);
      }}
      testProps={{
        elementName: "adminRemoveConfirmation",
        screenName: SCREEN_NAME,
      }}
      text="Removing this admin will revoke their admin merit immediately and they will no longer be able to access your Org Portal."
      title="Are you sure you want to remove this admin?"
      titleIconName="warningMediumCritical"
    />
  );

  return (
    <>
      <View style={{ height: theme.spacing.xxl }} />
      <View style={styles.tabBody}>
        <ScrollView>
          <Datagrid
            columns={columns}
            loading={isFetching || isLoading || isRolesLoading}
            testProps={{
              elementName: "adminsListView",
              screenName: SCREEN_NAME,
            }}
          >
            <DatagridBody
              columns={columns}
              data={admins}
              testProps={{
                elementName: "adminsListView",
                screenName: SCREEN_NAME,
              }}
            />
          </Datagrid>
        </ScrollView>

        <Pagination
          disableNext={!pagination.hasNextPage}
          disablePrev={!pagination.hasPrevPage}
          onNext={() => {
            nextPage();
          }}
          onPrev={() => {
            prevPage();
          }}
          testProps={{
            elementName: "adminsListView",
            screenName: SCREEN_NAME,
          }}
        />

        {Some(adminToRemove) && showRemoveAdminConfirmationModal()}
        {Some(adminToEdit) && (
          <EditAdminRolesModal
            adminMerit={adminToEdit}
            onClose={() => {
              setAdminToEdit(undefined);
              refresh();
            }}
            testProps={{
              elementName: "adminEditModal",
              screenName: SCREEN_NAME,
            }}
          />
        )}
      </View>
    </>
  );
};
