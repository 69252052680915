// Copyright 2024 Merit International Inc. All Rights Reserved

import { ErrorContent } from "@src/components/ErrorContent";
import { ResponseError } from "@src/gen/org-portal";
import { View } from "react-native";
import { useApi } from "@src/api/api";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useLoggedInUserRoles } from "@src/hooks/loggedInUserRoles";
import React, { useEffect, useState } from "react";

type Props = NonNullable<unknown>;

const SCREEN_NAME = "ReportsScreen";

export const ReportsScreen = ({}: Props) => {
  const { selectedOrgId } = useLoggedInAuthState();
  const { isDashboardAdmin } = useLoggedInUserRoles();
  const [url, setUrl] = useState<string>("");
  const [errorStatusCode, setErrorStatusCode] = useState<number>();
  const { DefaultLayout } = useFlaggedLayout();

  const { api } = useApi();

  useEffect(() => {
    if (!isDashboardAdmin) {
      return;
    }

    const fetchUrl = async () => {
      try {
        const response = await api.getSigmaEmbedURL({
          orgID: selectedOrgId,
        });

        setErrorStatusCode(undefined);
        setUrl(response.url);
      } catch (err) {
        if (err instanceof ResponseError) {
          setErrorStatusCode(err.response.status);
        } else {
          setErrorStatusCode(0); // Arbitrary status code to display error
        }
      }
    };
    fetchUrl();
  }, [api, selectedOrgId, isDashboardAdmin]);

  return (
    <DefaultLayout
      breadcrumbs={[{ name: "Create & Configure" }, { name: "Reports" }]}
      testProps={{ elementName: "reports", screenName: SCREEN_NAME }}
      title="Reports"
    >
      {errorStatusCode === undefined ? (
        <View style={{ flex: 1 }}>
          <iframe frameBorder="0" height="100%" src={url} />
        </View>
      ) : (
        <ErrorContent statusCode={errorStatusCode} />
      )}
    </DefaultLayout>
  );
};
